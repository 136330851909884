import React, { useState, useEffect } from "react";
import styled from "styled-components";
import SliderComponent from "../SliderComponent";
import { GridBox, Col, Line } from "../style_components/Grid";
import { color } from "../assets/styles";
import { FiPenTool } from "react-icons/fi";
import Footer from "../Footer";

export default () => {
  return (
    <Wrapper>
      <SliderComponent />
      <GridBox>
        <Col>MADE BY GINA</Col>
        <Col>
          <Line />
        </Col>
        <Col>
          Hej, jag heter Gina Engström och jobbar som AD och Grafisk Formgivare
          åt små företag och organisationer med stora drömmar.
        </Col>
        <Col>
          Som Art Director och Grafisk formgivare tar jag fram formstarka,
          effektiva och överraskande lösningar. Jag kan arbeta med hela eller
          delar av designprocessen. I mitt nätverk finns även fotografer,
          skribenter, webbutvecklare, projektledare samt produktionsledare om
          projektet eller du behöver det. Mina kunder är mestadels
          organisationer, kommuner, myndigheter och företag, som alla har
          gemensamt att vilja göra skillnad.
        </Col>
      </GridBox>
      <Section>
        <GridBox>
          <Col>
            <SubHead>Jag gör bland annat:</SubHead>
          </Col>
          <Col>
            <Option>Visuella identiteter</Option>
            <Option>Designmanualer</Option>
            <Option>Logotyper</Option>
            <Option>Illustrationer & infografik</Option>
            <Option>Utbildningsmaterial</Option>
            <Option>Årsredovisningar</Option>
            <Option>Powerpoint-presentationer</Option>
            <Option>Informationsmaterial</Option>
            <Option>Banners</Option>
            <Option>Webb-design/utveckling</Option>
          </Col>
          <Col>
            <SubHead>För bland annat:</SubHead>
          </Col>
          <Col>
            <Option>Konkurrensverket</Option>
            <Option>
              UHR, Utbildningsrådet för Hotell- och Restaurangfacket
            </Option>
            <Option>Kommunal fackförbund</Option>
            <Option>NSPH, Nationell Samverkan för Psykisk Hälsa</Option>
            <Option>SBR, Svenska Byggingenjörernas Riksförbund</Option>
            <Option>Psykologisk Metod</Option>
            <Option>Ide&Art</Option>
            <Option>Familjebostäder</Option>
            <Option>Österåkers kommun</Option>
            <Option>Skanska</Option>
            <Option>Socialstyrelsen</Option>
          </Col>
        </GridBox>
      </Section>
      <Footer />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
`;

const Section = styled.div`
  width: 100%;
  background-color: ${color.greenBlue};
  color: white;
`;

// const PenIcon = styled(FiPenTool)`
//   font-size: 40px;
// `;

const Option = styled.li`
  position: relative;
  /* &:before {
    content: " ";
    position: absolute;
    top: 14px;
    left: -20px;
    height: 5px;
    width: 5px;
    border-radius: 10px;
    transform: translateY(-50%);
    background-color: white;
  } */
`;

const SubHead = styled.div`
  font-weight: 700;
`;
