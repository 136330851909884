import React from "react";
import styled from "styled-components";
import { GridBox, Col, Line } from "../../style_components/Grid";
import ImgSlider from "./ImgSlider";
import {
  arsredovisningar,
  grafiskdesign,
  logor,
  illustration,
  grafikaprofiler
} from "./assets/workAssets";
import Footer from "../../Footer";

export default function Work() {
  return (
    <Wrapper>
      <Spacer height="10vh" />

      <GridBox>
        <Col>
          <Bold>EXEMPEL PÅ JOBB</Bold>
        </Col>
        <Col>
          <WorkLink href="#grafiskdesign">Grafisk Design</WorkLink>
          <WorkLink href="#arsredovsningar">Årsredovsiningar</WorkLink>
          <WorkLink href="#logor">Logotyper</WorkLink>
          <WorkLink href="#grafikaprofiler">Grafiska Profiler</WorkLink>
          <WorkLink href="#illustration">Illustration</WorkLink>
        </Col>
        <Col></Col>
        <Col>
          <Line />
        </Col>
      </GridBox>

      {/* <Spacer height="10vh" /> */}

      <WorkTarget id="grafiskdesign">
        <ImgSlider data={grafiskdesign} title={"Grafisk Design"} />
      </WorkTarget>

      <WorkTarget id="arsredovsningar">
        <ImgSlider data={arsredovisningar} title={"Årsredovisningar"} />
      </WorkTarget>

      <WorkTarget id="logor">
        <ImgSlider data={logor} title={"Logotyper"} />
      </WorkTarget>

      <WorkTarget id="grafikaprofiler">
        <ImgSlider data={grafikaprofiler} title={"Grafika Profiler"} />
      </WorkTarget>

      <WorkTarget id="illustration">
        <ImgSlider data={illustration} title={"Illustration"} />
      </WorkTarget>
      <Footer />
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
`;

const Bold = styled.span`
  font-weight: 700;
`;

const WorkLink = styled.a`
  text-decoration: underline;
`;

const WorkTarget = styled.div`
  width: 100%;
`;

const Spacer = styled.div`
  height: ${props => props.height};
`;
