import React from "react";
import styled from "styled-components";
import Menu from "./Menu";
import { BrowserRouter as Router, Route, Link } from "react-router-dom";
import Work from "./views/work/Work";
import Landing from "./views/Landing";
// import { color } from "./assets/styles";
import Footer from "./Footer";
import Kontakt from "./views/Kontakt";

export default function App() {
  return (
    <Wrapper>
      <Router>
        <Menu />

        <Route exact path="/" component={Landing} />
        <Route path="/jobb" component={Work} />
        <Route path="/kontakt" component={Kontakt} />
      </Router>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  width: 100%;
  /* height: 100%; */
`;
