import React, { useState, useEffect } from "react";
import styled, { keyframes } from "styled-components";
import bgArray from "./assets/img/bg_v2";

const delay = 3000;

export default function SliderComponent({ children }) {
  const [activeSlide, setActiveSlide] = useState(0);

  useEffect(() => {
    const timer = setInterval(() => {
      setActiveSlide(oldState => {
        if (oldState < bgArray.length - 1) {
          return oldState + 1;
        } else {
          return 0;
        }
      });
    }, delay);
    return () => clearInterval(timer);
  }, []);

  return (
    <Wrapper>
      <SliderWrapper>
        {bgArray.map((img, i) => (
          <Slide
            img={img}
            key={i}
            order={i}
            className={i === activeSlide && "ontop"}
          />
        ))}
      </SliderWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  position: relative;
  height: 70vh;
  width: 100%;
`;

const SliderWrapper = styled.div`
  position: relative;

  z-index: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  /* border: 1px solid blue; */
`;

const Slide = styled.div`
  position: absolute;
  height: 100%;
  width: 100%;
  background: ${props => `url(${props.img})`};
  background-size: cover;
  background-position: center center;

  opacity: 0;
  transform: scale(1.02);

  transition: all ${delay * 0.7 + "ms"} ease-out;

  &.ontop {
    opacity: 1;
    transform: scale(1);
  }
`;
