import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { GridBox, Col } from "./style_components/Grid";
import ginaimg from "./assets/img/gina.png";

export default () => {
  return (
    <Container>
      <GinaImg src={ginaimg} />
      <TextCont>
        <span style={{ fontWeight: "bold" }}>Gina Engström</span>
        <br />
        <Link href="mailto:gina@madebygina.se">gina@madebygina.se</Link>
        <br />
        <Link href="tel:+46708252430">+46 (0) 708 - 25 24 30</Link>
      </TextCont>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  max-width: 400px;
  margin: 70px auto;
  /* background-color: lightgrey; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const GinaImg = styled.img`
  width: 100px;
  border-radius: 50px;
  box-shadow: 0 2px 14px 0 rgba(0, 0, 0, 0.15);
`;

const TextCont = styled.div`
  margin-top: 1em;
  text-align: center;
  line-height: 1.5em;
`;

const Link = styled.a`
  &:hover {
    text-decoration: underline;
  }
`;
