import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Col, Col3Span, GridBox } from "../../style_components/Grid";
import { FiArrowRight, FiArrowLeft } from "react-icons/fi";
import { color } from "../../assets/styles";

export default ({ data, title }) => {
  const [currI, setCurrI] = useState(0);

  const endOfArray = currI + 1 === data.length;
  const startOfArray = currI - 1 < 0;

  const goNext = () => {
    setCurrI(currI + 1);
  };

  const goPrev = () => {
    setCurrI(currI - 1);
  };

  return (
    <GridBox>
      <Col>
        <Title>{title}</Title>
        <Spacer />
        <div>
          <Description>{data[currI].text}</Description>
          <MiniNavCont>
            {data.map((el, i) => (
              <MiniNavCirc
                className={i === currI && "active"}
                key={i}
                onClick={() => setCurrI(i)}
              />
            ))}
          </MiniNavCont>
        </div>
      </Col>
      <Col3Span>
        <NavCont>
          {!startOfArray && (
            <Back onClick={goPrev}>
              <FiArrowLeft />
            </Back>
          )}

          {!endOfArray && (
            <Next onClick={goNext}>
              <FiArrowRight />
            </Next>
          )}
        </NavCont>
        <Img src={data[currI].img} />
      </Col3Span>
    </GridBox>
  );
};

const Title = styled.div`
  text-decoration: underline;
`;

const Description = styled.div`
  opacity: 0.5;
  margin-bottom: 10px;
`;

const MiniNavCont = styled.div`
  display: flex;
`;

const MiniNavCirc = styled.div`
  height: 15px;
  width: 15px;
  margin-right: 5px;
  border-radius: 30px;
  background-color: rgba(0, 0, 0, 0.1);
  /* border: 1px solid rgba(0, 0, 0, 0.1); */
  cursor: pointer;
  &.active {
    cursor: initial;
    background-color: ${color.greenBlue};
    border: none;
  }
`;

const Img = styled.img`
  position: relative;
  z-index: 1;
  width: 100%;
  height: auto;
  display: block;
  user-select: none;
`;

const NavCont = styled.div`
  position: absolute;
  z-index: 5;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

const NavArea = styled.div`
  position: absolute;
  top: 0;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
  font-size: 10vw;
  opacity: 0;
  transition: all 200ms linear;
  &:hover {
    opacity: 1;
  }
`;

const Back = styled(NavArea)`
  left: 0;
`;

const Next = styled(NavArea)`
  left: 50%;
`;

const Spacer = styled.div`
  flex: 1;
`;
