import React from "react";

import Armada from "../../../assets/img/work/arsredovisningar/1_Armada.jpg";
import fam2 from "../../../assets/img/work/arsredovisningar/2_fam2.jpg";
import Oak from "../../../assets/img/work/arsredovisningar/3_Oak.jpg";
import Centrumkomp from "../../../assets/img/work/arsredovisningar/4_centrumkompaniet.jpg";
import iogt from "../../../assets/img/work/arsredovisningar/5_iogt2.jpg";

import nsph_arbetsbok from "../../../assets/img/work/grafiskdesign/1_nsph_arbetsbok.jpg";
import nsph_vagavara from "../../../assets/img/work/grafiskdesign/2_nsph_vagavara.jpg";
import kommunal from "../../../assets/img/work/grafiskdesign/3_kommunal.jpg";
import uhr_lathundar from "../../../assets/img/work/grafiskdesign/4_uhr_lathundar.jpg";
import sbr from "../../../assets/img/work/grafiskdesign/5_sbr.jpg";
import kkv from "../../../assets/img/work/grafiskdesign/6_kkv.jpg";

import solvallastaden from "../../../assets/img/work/logor/1_solvallastaden.jpg";
import spread_1 from "../../../assets/img/work/logor/2_1-4.jpg";
import vartrygghet from "../../../assets/img/work/logor/3_vartrygghet.jpg";
import eurobasket from "../../../assets/img/work/logor/4_eurobasket.jpg";
import spread_2 from "../../../assets/img/work/logor/5_5-7_new.jpg";
import Livs from "../../../assets/img/work/logor/6_Livs.jpg";
import spread_3 from "../../../assets/img/work/logor/6_8-10.jpg";
import nsph from "../../../assets/img/work/logor/7_nsph_rattspsyk.jpg";

import Goteborg from "../../../assets/img/work/illustration/1_Goteborg.jpg";
import memories from "../../../assets/img/work/illustration/2_memories.jpg";
import kids from "../../../assets/img/work/illustration/3_kids.jpg";
import happyhorse_textil from "../../../assets/img/work/illustration/4_happyhorse_textil.jpg";
import sthlm_barn from "../../../assets/img/work/illustration/5_sthlm barn.jpg";

import Profil_nykvarn2 from "../../../assets/img/work/grafiskaprofiler/1_Profil_nykvarn2.jpeg";
import Profil_sigtuna from "../../../assets/img/work/grafiskaprofiler/2_Profil_sigtuna.jpeg";
import Profil_osteraker from "../../../assets/img/work/grafiskaprofiler/3_Profil_osteraker.jpg";
import Profil_haninge from "../../../assets/img/work/grafiskaprofiler/4_Profil_haninge.jpg";

export const arsredovisningar = [
  {
    text: "Armada Fastighets AB",
    img: Armada,
  },
  {
    text: "AB Familjebostäder",
    img: fam2,
  },
  {
    text: "Österåkers kommun",
    img: Oak,
  },
  {
    text: "Centrumkompaniet. Illustration: Molly Bartling",
    img: Centrumkomp,
  },
  {
    text: "IOGT-NTO",
    img: iogt,
  },
];

export const grafiskdesign = [
  {
    text: (
      <>
        NSPH, Nationell samverkan för psykisk hälsa. Kursmaterial i syfte att
        stärka ungdomars inflytande i livet.
        <br />
        Illustratör: Lotta Sjöberg
      </>
    ),
    img: nsph_arbetsbok,
  },
  {
    text: (
      <>
        NSPH, Nationell samverkan för psykisk hälsa. Infomationsmaterial för
        vuxna som möter barn.
        <br />
        Illustratör: Lotta Sjöberg
      </>
    ),
    img: nsph_vagavara,
  },
  {
    text: (
      <>
        Fackförbundet Kommunal
        <br />
        Medlemsmaterial
      </>
    ),
    img: kommunal,
  },
  {
    text: (
      <>
        UHR, utbildningsrådet för Hotell- och Restauranger. <br />
        Utbildningsmaterial
      </>
    ),
    img: uhr_lathundar,
  },
  {
    text: (
      <>
        SBR, Svenska Byggingenjörernas Riksförbund
        <br />
        Kurskatalog{" "}
      </>
    ),
    img: sbr,
  },
  {
    text: (
      <>
        Konkurrensverket
        <br />
        Diverse publikationer
      </>
    ),
    img: kkv,
  },
];

export const logor = [
  {
    text: "",
    img: solvallastaden,
  },
  {
    text: "",
    img: spread_1,
  },
  {
    text: "",
    img: vartrygghet,
  },
  {
    text: "",
    img: eurobasket,
  },
  {
    text: "",
    img: spread_2,
  },
  // {
  //   text: "",
  //   img: Livs
  // },
  {
    text: "",
    img: spread_3,
  },
  {
    text: "",
    img: nsph,
  },
];

export const illustration = [
  {
    text: (
      <>
        I samarbete med Ide&Art har jag skapat flera kollektioner för den
        svenska turistnäringen. Här är några exempel.
        <br />
        Illustrationer av Gina.
      </>
    ),
    img: Goteborg,
  },
  {
    text: (
      <>
        Memories of Sweden
        <br />
        T-shirts, brickor, handdukar, disktrasor, coasters etc{" "}
      </>
    ),
    img: memories,
  },
  {
    text: "Kids of Sweden",
    img: kids,
  },
  {
    text:
      "Welcome to the land with the Happy Horses. Finns som T-shirts och hemtextil.",
    img: happyhorse_textil,
  },
  {
    text: "Stockholm Barntröja",
    img: sthlm_barn,
  },
];

export const grafikaprofiler = [
  {
    text: (
      <>
        Jag har haft förmånen att arbeta fram flera kommuners grafiska profiler
        från grunden. Här är några av dessa modiga. <br />
        Nykvarns kommun
      </>
    ),
    img: Profil_nykvarn2,
  },
  {
    text: "Sigtuna kommun",
    img: Profil_sigtuna,
  },
  {
    text: "Österåkers kommun",
    img: Profil_osteraker,
  },
  {
    text: "Haninge kommun",
    img: Profil_haninge,
  },
];
