import img_1 from "./1.jpg";
import img_2 from "./2.jpg";
import img_3 from "./3.jpg";
import img_4 from "./4.jpg";
import img_5 from "./5.jpg";
import img_6 from "./6.jpg";
import img_7 from "./7.jpg";
import img_8 from "./8.jpg";
import img_9 from "./9.jpg";
import img_10 from "./10.jpg";
import img_11 from "./11.jpg";

const bgArray = [
  img_1,
  img_2,
  img_3,
  img_4,
  img_5,
  img_6,
  img_7,
  img_8,
  img_9,
  img_10,
  img_11
];

export default bgArray;
