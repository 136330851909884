import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";
import { FiMenu, FiX } from "react-icons/fi";
import { NavLink } from "react-router-dom";
import Footer from "./Footer";

export default function Menu() {
  const [open, setOpen] = useState(false);
  const closeMenu = () => setOpen(false);

  return (
    <>
      <Icon onClick={() => setOpen(!open)}>{open ? <FiX /> : <FiMenu />}</Icon>
      <WhiteBg className={open && "open"}>
        <Content className={open && "open"}>
          <Footer />
          <LinksCont>
            <NavLinkStyled to="/" onClick={closeMenu}>
              HEM
            </NavLinkStyled>
            <NavLinkStyled to="/jobb" onClick={closeMenu}>
              JOBB
            </NavLinkStyled>
          </LinksCont>
        </Content>
      </WhiteBg>
    </>
  );
}

const WhiteBg = styled.div`
  position: fixed;
  z-index: 100;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  border-radius: 35px;
  background-color: white;
  box-shadow: 0 2px 10px -2px rgba(0, 0, 0, 0.15);
  opacity: 0.95;

  transition: all 500ms cubic-bezier(0.04, 0.81, 0.29, 1.01);

  &.open {
    opacity: 1;
    top: 0px;
    right: 0px;
    height: 100%;
    width: 300px;
    border-radius: 0px;
  }
`;

const Icon = styled.div`
  position: fixed;
  z-index: 101;
  top: 10px;
  right: 10px;
  height: 50px;
  width: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  cursor: pointer;
`;

const Content = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  opacity: 0;
  transition: all 100ms linear;
  transition-delay: 0;
  &.open {
    opacity: 1;
    transition: all 800ms linear;
    transition-delay: 500ms;
  }
`;

const LinksCont = styled.div`
  display: flex;
`;

const NavLinkStyled = styled(NavLink)`
  padding: 0.5em 1.5em;
  margin: 0 1em;
  border-radius: 100px;
  border: 1px solid black;
  font-weight: bold;
  &:hover {
    background-color: lightgrey;
  }
`;
